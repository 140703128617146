import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import parse from "html-react-parser";
import SecondaryHero from "../../components/secondary-hero";
import EventSection from "../../components/event-section";

const OurFundingPage = ({ transitionStatus }) => {
	const data = useStaticQuery(graphql`
		query {
			heroImgMain: wpMediaItem(title: { eq: "Home Hero Main" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			wpPageContent(id: { eq: "cG9zdDo0NzA=" }) {
				content
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "About Us",
				item: {
					url: `${siteUrl}/about-us`,
					id: `${siteUrl}/about-us`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "Our Funding",
				item: {
					url: `${siteUrl}/about-us/our-funding`,
					id: `${siteUrl}/about-us/our-funding`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title="Our Funding | Friends of Eastbourne Hospital"
				description="Funds are raised for the Friends in a number of ways, and all are vital in our mission to support the hospital."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/about-us/our-funding`,
					title: "Our Funding | Friends of Eastbourne Hospital",
					description:
						"Funds are raised for the Friends in a number of ways, and all are vital in our mission to support the hospital.",
					images: [
						{
							url: `${data.heroImgMain?.localFile.publicURL}`,
							width: `${data.heroImgMain?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImgMain?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImgMain?.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<section className="pb-5">
					<SecondaryHero
						title="Our Funding"
						description={
							<span>
								Funds are raised for the Friends in a number of ways,
								<br className="d-none d-md-block" /> and all are vital in our
								mission to support the hospital.
							</span>
						}
					/>
				</section>
				<section className="pb-5">
					<Container>
						<Row>
							<Col className="page-content our-funding">
								{parse(data.wpPageContent.content)}
							</Col>
						</Row>
					</Container>
				</section>

				<section>
					<EventSection />
				</section>
			</Layout>
		</>
	);
};

export default OurFundingPage;
